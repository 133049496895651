import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`20 minutes to establish a max complex of:`}</p>
    <p>{`1-Power Clean`}</p>
    <p>{`1-Hang Power Clean`}</p>
    <p>{`1-High Hang Squat Clean`}</p>
    <p>{`then,`}</p>
    <p>{`5 Rounds for time of:`}</p>
    <p>{`7-Front Squats (155/105)`}</p>
    <p>{`14-K2E’s`}</p>
    <p><em parentName="p">{`*`}{`compare to 8/26/19`}</em></p>
    <p><strong parentName="p">{`*`}{`*`}{`We are sold out for the Granite Games Spring Throwdown!  We do
still need a few judges (level 1 or past judging experience is
preferred). Sunday, February 16th is the last day to get your volunteer
t shirt so if you can, please consider helping.  **`}</strong>{`Sign up to
volunteer at: `}<a parentName="p" {...{
        "href": "https://competitioncorner.net/events/3164"
      }}>{`https://competitioncorner.net/events/3164`}</a>{`***`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      